var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-brand-gray-100 min-h-full"},[_c('PageHeader',[_c('div',{staticClass:"flex items-center"},[_c('router-link',{staticClass:"px-2 -ml-2 py-1 inline-block focus:outline-none",attrs:{"to":"profile"}},[_c('svg',{staticClass:"feather feather-chevron-left",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"15 18 9 12 15 6"}})])])],1),_c('h1',{staticClass:"font-medium"},[_vm._v("Holidays")])]),_c('main',{staticClass:"mt-4"},[_c('div',{staticClass:"text-center px-6"},[(typeof _vm.holidayDaysLeft === 'number')?_c('h2',{staticClass:"text-6xl font-light leading-normal"},[_vm._v(" "+_vm._s(_vm.holidayDaysLeft)+" ")]):_c('div',{staticClass:"h-24"}),_c('p',{staticClass:"-mt-2"},[_vm._v("days off left")])]),_c('div',{staticClass:"mt-6 text-center px-6"},[_c('h2',{staticClass:"text-xl"},[_vm._v("Request new holiday")]),_c('p',{staticClass:"text-sm mt-2 font-light max-w-xl mx-auto"},[_vm._v(" Before requesting days off, talk to your Head of Department (in case you don’t have one, you can ask Pau). Check if it’s good timing to book your holidays on the desired dates. ")]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"mt-8",attrs:{"action":"","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"name":"Date","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-date-picker',{attrs:{"mode":"multiple","disabled-dates":{ weekdays: [1, 7] },"locale":_vm.locale},model:{value:(_vm.inputs.dates),callback:function ($$v) {_vm.$set(_vm.inputs, "dates", $$v)},expression:"inputs.dates"}},[_c('input',{staticClass:"block w-full px-0 bg-transparent border-0 border-b border-black placeholder-black border-opacity-10 placeholder-opacity-20 py-1 font-light focus:border-black focus:border-opacity-100 focus:ring-0 transition",attrs:{"type":"text","aria-label":"Dates","placeholder":"Select dates","readonly":""},domProps:{"value":_vm.formatedInputDates}})]),_c('div',{staticClass:"h-6 text-sm flex items-center text-red-500 font-light"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputs.comments),expression:"inputs.comments"}],staticClass:"mt-2 block w-full px-0 bg-transparent border-0 border-b border-black placeholder-black border-opacity-10 placeholder-opacity-20 py-1 font-light focus:border-black focus:border-opacity-100 focus:ring-0 transition",attrs:{"name":"Comment","id":"comment","aria-label":"Comment","placeholder":"Add more information here"},domProps:{"value":(_vm.inputs.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputs, "comments", $event.target.value)}}}),_c('div',{staticClass:"h-6 text-sm flex items-center text-red-500 font-light"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('button',{staticClass:"mt-6 w-56 py-3 border border-black rounded-full outline-none",attrs:{"type":"submit"}},[_vm._v(" Request "),(_vm.inputs.dates.length === 1)?_c('span',[_vm._v("1 day")]):(_vm.inputs.dates.length > 1)?_c('span',[_vm._v(_vm._s(_vm.inputs.dates.length)+" days")]):_vm._e()])],1)]}}])})],1),_c('div',{staticClass:"mt-10"},[_c('h2',{staticClass:"text-center text-xl"},[_vm._v("Days requested")]),_c('div',{staticClass:"mt-3 font-light"},[(_vm.holidays.length)?_c('ul',{staticClass:"divide-y border-t border-b border-brand-gray-200 divide-brand-gray-200"},_vm._l((_vm.holidays),function(holiday){return _c('li',{key:holiday.id,staticClass:"px-4 py-2 flex items-center justify-between bg-white h-16 max-w-full"},[_c('div',{staticClass:"flex flex-1 items-center overflow-hidden"},[_c('span',{staticClass:"flex-shrink-0 min-w-date"},[_vm._v(_vm._s(_vm.format(_vm.parseISO(holiday.date), "do MMM")))]),(holiday.comments)?_c('span',{staticClass:"ml-1 opacity-50 truncate text-sm"},[_vm._v(" "+_vm._s(holiday.comments)+" ")]):_vm._e()]),_c('div',{staticClass:"ml-3 h-4 pointer-events-none flex-shrink-0",class:{
                'text-green-400': holiday.status === 'approved',
                'text-red-400': holiday.status === 'denied',
              }},[_c('svg',{staticClass:"h-full",attrs:{"viewBox":"0 0 100 100"}},[_c('circle',{attrs:{"cx":"50","cy":"50","r":"40","fill":"currentColor"}})])])])}),0):_c('span',{staticClass:"px-4"},[_vm._v(" Time to think about taking some days off maybe? ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }